/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState } from "react";
import Light from "../../Assests/sun.svg";
import dark from "../../Assests/moon.svg";
// import DropDown from "../Dropdown";
import style from "./preNav.module.scss";
import Bitcoin from "../../Assests/bitcoin.svg";
import litecoin from "../../Assests/litecoin.svg";
import Ethrem from "../../Assests/ethrm.svg";
import Bitcoing from "../../Assests/dollar.svg";
import { useSelector } from "react-redux";
const PreNav = ({ isActive, handleClick }) => {
  const marketRateInUSD = useSelector((state) => state.Contact.marketRateInUSD);
  const TopCoins = useSelector((state) => state.CoinDetail.TopCoins);
  console.log("🚀 ~ file: index.js:15 ~ PreNav ~ TopCoins:", TopCoins)

  const [currency] = useState("USD");

  // const options = ["euro", "Dollar", "euro", "Dollar", "euro"];
  const image = {
    ETH: Ethrem,
    USD: Bitcoing,
    BTC: Bitcoin,
    LTC: litecoin,
  };

  return (
    <div>
      <div className="container w-full overflow-x-auto xl:px-4 px-4 lg:px-8 mx-auto">
        <div className="flex items-center">
          <marquee direction="right" className="sm:flex lg:hidden">
            {" "}
            <div className="order-2 md:order-1 ml-3 md:ml-0">
              <ul className="flex gap-x-4 mt-0">
                <li>
                  <div
                    className=" lg:text-[14px] text-[12px] whitespace-nowrap text-[#A968E9] rounded   flex items-center flex-nowrap"
                    aria-current="page"
                  >
                    Coin Official
                    <label className="text-black dark:text-white ml-2 lg:text-[14px] text-[12px]">
                      ${marketRateInUSD ? marketRateInUSD?.toFixed(3) : 0}
                    </label>
                    <label className="text-[#3FB791] ml-1 text-[8px]">
                      {" "}
                      0%{" "}
                    </label>
                  </div>
                </li>
                {TopCoins?.map((item, index) => {
                  if (index === 2) {
                    return null
                  } else {
                    return (
                      <li className="flex items-center gap-x-2" key={index}>
                        <div className=" lg:text-[14px] text-[12px]  text-[#F7931A]  rounded  flex items-center ">
                          {item?.token_symbol?.toUpperCase()}
                          <div className="text-[black] dark:text-[white] ml-2 lg:text-[14px] text-[12px]">
                            ${Number(item?.price_usd)}
                          </div>
                          {Number(item?.price_24h_percent_change) > 0 ? (
                            <div className="text-[#41c93d] ml-1 text-[10px] ">
                              {Number(item?.price_24h_percent_change)?.toFixed(2)}%
                            </div>
                          ) : (
                            <div className="text-[#E86062] ml-1 text-[10px] ">
                              {Number(item?.price_24h_percent_change)?.toFixed(2)}%
                            </div>
                          )}
                        </div>

                      </li>
                    )
                  }
                })}
              </ul>
            </div>
          </marquee>

          <div className="order-2 md:order-1 ml-3 md:ml-0 hidden lg:flex">
            <ul className="flex gap-x-4 mt-0">
              <li>
                <div
                  className=" lg:text-[14px] text-[12px] whitespace-nowrap text-[#A968E9] rounded   flex items-center flex-nowrap"
                  aria-current="page"
                >
                  Coin Official
                  <div className="text-black dark:text-white ml-2 lg:text-[14px] text-[12px]">
                    ${marketRateInUSD ? marketRateInUSD?.toFixed(3) : 0}
                  </div>
                  <div className="text-[#3FB791] ml-1 text-[8px]"> 0% </div>
                </div>
              </li>
              {TopCoins?.map((item, index) => {
                if (index === 2) {
                  return null
                } else {
                  return (
                    <li className="flex items-center gap-x-2" key={index}>
                      <div className=" lg:text-[14px] text-[12px]  text-[#F7931A]  rounded  flex items-center ">
                        {item?.token_symbol?.toUpperCase()}
                        <div className="text-[black] dark:text-[white] ml-2 lg:text-[14px] text-[12px]">
                          ${Number(item?.price_usd)}
                        </div>
                        {Number(item?.price_24h_percent_change) > 0 ? (
                          <div className="text-[#41c93d] ml-1 text-[10px] ">
                            {Number(item?.price_24h_percent_change)?.toFixed(2)}%
                          </div>
                        ) : (
                          <div className="text-[#E86062] ml-1 text-[10px] ">
                            {Number(item?.price_24h_percent_change)?.toFixed(2)}%
                          </div>
                        )}
                      </div>

                    </li>
                  )
                }
              })}


            </ul>
          </div>

          <div className="  md:ml-auto order-1 md:order-2">
            <div className="flex justify-end">
              <div className={style.rightside}>
                <img src={image[currency]} alt="SelectImage" />
                <p className="dark:text-white ml-2">USD</p>
              </div>
              {isActive ? (
                <img
                  src={Light}
                  alt="Dark"
                  className="object-contain ml-5 my-2 hover:cursor-pointer "
                  onClick={handleClick}
                />
              ) : (
                <img
                  src={dark}
                  alt="Light"
                  className="object-contain ml-4 my-2 hover:cursor-pointer"
                  onClick={handleClick}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PreNav;
