import React, { useEffect, useState, lazy, Suspense } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReferralClaim } from "./Components/ReferralsClaim";
import Layout from "./Components/__common/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import PageNotFound from "./Components/PageNotFound";
import { NftClaim } from "./Components/NFTClaim";
import { handleUserReferral } from "./services/Home";
import {
  referralCode,
  saveChain,
  savePartners,
} from "./store/Reducers/homereducer";
import Cart from "./Components/Cart";
import Maintainence from "./Components/Maintenance";
import { saveContact, saveProfitWallet } from "./store/Reducers/contact";
import { getAllData } from "./services/contactServices";
import LoadingScreen from "./Components/__common/loadingScreen";
import { saveMarketCap, saveTopCoins } from "./store/Reducers/coinDetail";
import { HelmetProvider } from "react-helmet-async";
import MobileUIWalletModal from "./Components/__common/Mobile-UI-Wallet";
import { initGA } from "./Components/__common/GoogleAnalytic";
import Scrollbar from "./Components/__common/Scrollbar";
const CoinForm = lazy(() => import("./Components/AddCoins"));
const HomePage = lazy(() => import("./Components/Home"));
const ListCoin = lazy(() => import("./Components/ListCoins"));
const NewsDetails = lazy(() => import("./Components/News/NewsDetail"));
const Nft = lazy(() => import("./Components/Nft"));
const PortfolioComp = lazy(() => import("./Components/Portfolio"));
const Promote = lazy(() => import("./Components/Promote"));
const Staking = lazy(() => import("./Components/Staking"));
const PermoteGraph = lazy(() => import("./Components/Promote/PermoteGraph"));
const MarketPlace = lazy(() => import("./Components/MarketPlace"));
const UnStake = lazy(() => import("./Components/UnStake"));
const ContactComponent = lazy(() => import("./Components/Services"));
const News = lazy(() => import("./Components/News"))
const PoolDetailComp = lazy(() => import("./Components/Staking/PoolDetail"))

const App = () => {
  const navigate = useNavigate();
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  const useMobileWallet = useSelector((state) => state.token.useMobileWallet);
  const [maintain, setMaintain] = useState(true);
  const dispatch = useDispatch();
  console.error = function () { };

  // If a user submits a form for adding a new coin without providing any referral code, this function will automatically generate it on Add Coin Page

  const userRefferal = async () => {
    let param = {
      address: metaMaskToken,
    };
    const response = await handleUserReferral(param);
    dispatch(referralCode(response.data.referralCode));
  };

  // const getNormalWalletAddress = async () => {
  //   const response = await handleNormalWallet();

  //   const normalWalletAddress = response.data.find(
  //     (m) => m.type == NATIVE_WALLETS_TYPE.PROJECT_WALLET
  //   );
  //   dispatch(normalWallet(normalWalletAddress.address));
  // };

  // This function verifies the maintenance mode value from the API //

  const getMaintenenceMode = async () => {
    try {
      const resp = await getAllData();

      dispatch(saveContact(resp?.data?.settings));
      setMaintain(resp?.data?.settings?.maintenance);
      dispatch(saveChain(resp?.data?.chains));
      dispatch(savePartners(resp?.data?.poweredBy));
      dispatch(saveProfitWallet(resp?.data?.externalWallets));
      dispatch(saveContact(resp?.data?.settings));
      dispatch(saveMarketCap(resp?.data?.globalMetrics?.data?.quote?.USD));
      let response = resp?.data?.topCoins;
      const filterTokens = response.filter(item => item?.token_name === "Binance USD" || item?.token_name === "BNB" || item?.token_name === "Wrapped Ether" || item?.token_name === "Wrapped BTC")
      dispatch(saveTopCoins(filterTokens));
    } catch (error) {

    }
  };

  useEffect(() => {
    if (metaMaskToken) {
      userRefferal();
    }
    // getNormalWalletAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaMaskToken]);

  useEffect(() => {
    getMaintenenceMode();
    initGA()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Scrollbar>
      <Suspense fallback={<LoadingScreen />}>
        {maintain ? (
          <HelmetProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/list-coins" element={<ListCoin />} />
                <Route path="/add-coins" element={<CoinForm />} />
                <Route path="/claim" element={<ReferralClaim />} />
                <Route path="/promote" element={<Promote />} />

                <Route path="/contact" element={<ContactComponent />} />

                <Route path="/news" element={<News />} />

                <Route path="/news/:newsSlug" element={<NewsDetails />} />

                <Route
                  path="/:coinName/:coinSymbol"
                  element={<PermoteGraph navigate={navigate} />}
                />

                <Route path="/marketplace" element={<MarketPlace />} />

                <Route path="/cart" element={<Cart />} />

                <Route
                  path="/unstake/:contract/:poolId"
                  element={<UnStake navigate={navigate} />}
                />

                <Route path="*" element={<PageNotFound />} />
                <Route
                  path="/staking"
                  element={<Staking navigate={navigate} />}
                />
                <Route
                  path="/staking/:CoinName"
                  element={<PoolDetailComp />}
                />
                <Route path="/nft" element={<Nft />} />
                {/* <Route path="/nftclaim" element={<NftClaim />} /> */}

                <Route path="/nft-claim" element={<NftClaim />} />

                <Route path="/account" element={<PortfolioComp />} />
              </Routes>
            </Layout>
          </HelmetProvider>
        ) : (
          <Routes>
            <Route path="*" element={<Maintainence />} />
          </Routes>
        )}
      </Suspense>
      {useMobileWallet && !metaMaskToken ? <MobileUIWalletModal /> : null}
    </Scrollbar>
  );
};
export default App;
