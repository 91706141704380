/* eslint-disable no-undef */
export const initGA = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());
  gtag("config", "G-QEQEBDEJ0G");
};


export const GA_TRACKING_ID = "G-QEQEBDEJ0G";

const trackPageView = (url) => {
  window?.gtag("config", GA_TRACKING_ID, {
    page_location: url,
  });
};

export const trackPageViewOnMount = (url) => {
  trackPageView(url);
};