/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./navbar.module.scss";
import { FiMenu } from "react-icons/fi";
import { useState } from "react";
import MobileMenu from "../MobileMenu";
import LightLogo from "../../Assests/LogoLight.svg";
import DarkLogo from "../../Assests/darkklogo.svg";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cart from "../../Assests/newcart.svg";
import ligthCart from "../../Assests/tokri.svg";
import disconect from "../../Assests/disconect.svg";
import { ChainID } from "../../../utils/constans/appconstans";
import UseModalInjector from "../../../utils/helper/useWeb3Modal";
import DropDown from "../Dropdown";
import { setMetaMaskToken } from "../../../store/Reducers/metamasktoken";
import { useEffect } from "react";
const Navigation = ({ isActive }) => {
  const {
    check,
    handleDisconnect,
    handleConnectModal,
    isWrongNetwork,
    chainId,
  } = UseModalInjector();

  const dispatch = useDispatch();
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);

  const { cart } = useSelector((state) => state.Home);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [selected, setSelected] = useState(
    `${metaMaskToken?.substring(0, 6)}...${metaMaskToken?.substring(
      metaMaskToken?.length - 4
    )}`
  );
  const options = ["disconnect"];

  // This function is used for the Mobile Menu toggle.

  const mobileMenuHandler = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // This useEffect Hook is used to remove the meta mask token from redux.

  useEffect(() => {
    if (selected === "disconnect") {
      dispatch(setMetaMaskToken(null));
      handleDisconnect();
      setSelected(
        `${metaMaskToken?.substring(0, 6)}...${metaMaskToken?.substring(
          metaMaskToken?.length - 4
        )}`
      );
    }
  }, [selected]);

  // This useEffect Hook is used to set the meta mask token to the selected state.

  useEffect(() => {
    setSelected(
      `${metaMaskToken?.substring(0, 6)}...${metaMaskToken?.substring(
        metaMaskToken?.length - 4
      )}`
    );
  }, [metaMaskToken]);

  return (
    <div className={styles.containerr}>
      <nav className={styles.wrapper}>
        <div className={styles.logo}>
          <NavLink to="/">
            {isActive ? (
              <img src={DarkLogo} width={170} height={45} alt="light Logo" />
            ) : (
              <img src={LightLogo} width={170} height={45} alt="dark Logo" />
            )}
          </NavLink>
        </div>
        {!isMobileMenuOpen && (
          <FiMenu onClick={mobileMenuHandler} className={styles.menuIcon} />
        )}
        <div className={styles.linkWrapper}>
          <div className={styles.links}>
            <NavLink
              to="/list-coins"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>List of Coins</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>
            <NavLink
              to="/add-coins"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>Add Coin</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>

            <NavLink
              to="/staking"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>Staking</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>

            <NavLink
              to="/account"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>Account</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>

            <NavLink
              to="/promote"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>Promote</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>
            <NavLink
              to="/news"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>News</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>
            <NavLink
              to="/marketplace"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>Marketplace</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>

            <NavLink
              to="/contact"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>Contact</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>

            <NavLink
              to="/nft"
              className={({ isActive }) =>
                isActive ? styles.active : styles.inactive
              }
            >
              <div className={styles.link}>
                <div className={styles.text}>NFT</div>
                <div className={styles.activeBar} />
              </div>
            </NavLink>
          </div>
          <div className="flex md:gap-x-3 lg:gap-x-4">
            <NavLink
              to="/cart"
              className={({ isActive }) =>
                isActive ? styles.activeCart : styles.inactiveCart
              }
            >
              <div className={styles.Cartlink}>
                <img
                  src={window.location.pathname === "/cart" ? ligthCart : Cart}
                  alt="cart"
                  className={
                    window.location.pathname === "/cart"
                      ? "cursor-pointer relative"
                      : "cursor-pointer relative invert dark:invert-0"
                  }
                />
                <div className={styles.activeBar} />
              </div>
            </NavLink>
            <div className={styles.count}>{cart?.length}</div>

            {metaMaskToken ? (
              <div className={styles.DropDownDiv}>
                <DropDown
                  color="white"
                  selected={selected}
                  isActive={isActive}
                  setSelected={setSelected}
                  options={options && options}
                  showRightIcon={false}
                />
              </div>
            ) : (
              <button onClick={handleConnectModal}>
                {/* {Account && chainId && chainId !== ChainID ? (
                  <img src={walletRed} alt="wallet-red" />
                ) : isWrongNetwork ? (
                  <img src={walletRed} alt="wallet-red" />
                ) : (
                  <img src={wallet} alt="wallet" />
                )} */}
                <span>
                  {metaMaskToken ? (
                    chainId === ChainID ? (
                      `${metaMaskToken?.substring(
                        0,
                        6
                      )}...${metaMaskToken?.substring(
                        metaMaskToken?.length - 4
                      )}`
                    ) : (
                      <span style={{ color: "#D33B44" }}>Wrong Network</span>
                    )
                  ) : isWrongNetwork ? (
                    <span style={{ color: "#D33B44" }}>Wrong Network</span>
                  ) : (
                    "Connect Wallet"
                  )}
                </span>
              </button>
            )}

            {check ? (
              <div className={styles.disconnect} onClick={handleDisconnect}>
                <img
                  src={disconect}
                  alt="disconnect"
                  className="cursor-pointer h-9 w-9"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </nav>
      {isMobileMenuOpen && <MobileMenu mobileMenuHandler={mobileMenuHandler} />}
    </div>
  );
};

export default Navigation;
