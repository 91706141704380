/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import TableData from "./ClaimTableData";
import styles from "./claimTable.module.scss";
import { getClaims } from "../../../services/claim";
import { useSelector } from "react-redux";
import { WaveDate } from "../../__common/loader/Loader";
import NoDataFound from "../../__common/noDataGif/noDataFound";
import { toast } from "react-toastify";

const ClaimTable = () => {
  let [claimData, setClaimData] = useState([]);
  let [loading, setLoading] = useState(false);
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);

  const claim = async () => {
    try {
      setLoading(true);
      const response = await getClaims(metaMaskToken);
      setClaimData(response?.data?.referralCommissions?.reverse());
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (metaMaskToken) {
      claim();
    }
  }, [metaMaskToken]);

  return (
    <div className={styles.bg}>
      <div className={styles.gridd}>
        <div className={styles.spann}>
          {loading ? (
            <div className="w-full min-h-[360px] flex dark:bg-[#1C2024] bg-[#edebef] justify-center items-center rounded-lg">
              <WaveDate />
            </div>
          ) : claimData?.length === 0 ? (
            <div className="w-full min-h-[360px] flex dark:bg-[#1C2024] justify-center rounded-lg">
              <NoDataFound />
            </div>
          ) : (
            <div class={styles.flow}>
              <table className={styles.table}>
                <thead class={styles.thead}>
                  <tr>
                    <th scope="col" class="py-3 px-6">
                      From
                    </th>
                    <th scope="col" class="py-3 px-6">
                      Commissions
                    </th>
                    <th scope="col" class="py-3 px-6"></th>
                    <th scope="col" class="py-3 px-6">
                      Referral ID
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {claimData?.map((item) => {
                    return (
                      <tr class={styles.trow} key={item?.id}>
                        <th scope="row" class={styles.head}>
                          <div className="flex">
                            <img
                              width="25px"
                              height="25px"
                              src={item?.coin?.logo}
                              className="mr-2"
                              alt=""
                            />
                            <p>{item?.coin?.name}</p>
                          </div>
                        </th>
                        <td class="py-4 px-6">
                          {item?.commission ? item?.commission.toFixed(4) : ""}
                        </td>
                        <td class="py-4 px-6"></td>
                        <td class="py-4 px-6">
                          {/* <button>{item.referralCode}</button> */}
                          {item?.referralCode}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClaimTable;
