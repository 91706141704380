import React from "react";
import Discord from "../../Assests/newDiscord.svg";
import Twitter from "../../Assests/newtwitter.svg";
import Telegram from "../../Assests/Newtelegram.svg";
import Github from "../../Assests/NewgitHub.svg";
import Newsletter from "../../Assests/newsletter.svg";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import Logo from "../../Assests/Group 2.png";
import { useState } from "react";
import Styles from "./footer.module.scss";
import LongArrow from "../../Assests/longArrow.svg";
import { store } from "../../../store";
import { addNewsletter } from "../../../services/Home";
import { toast, ToastContainer } from "react-toastify";
import { CommonButton } from "../loader/Loader";
import { useFormik } from "formik";
import * as yup from "yup";
import DarkImage from "../../Assests/whiteMoralis.svg";
import LightImage from "../../Assests/graymoralis.svg";
import { useSelector } from "react-redux";
const Footer = () => {
  // States //

  const [imgSrc, setImgSrc] = useState(Newsletter);
  const [email, setEmail] = useState("");
  const [loading, setloading] = useState(false);
  const isActive = useSelector((state) => state.Layout.isActive);
  const contact = store.getState()?.Contact.data;
  const twitter = contact.twitter;
  const discord = contact.discord;
  const telegram = contact.telegram;
  const github = contact.github;

  // this formikSchema is use to validate the input values and sending the object to the handleSubmit function //

  const formikSchema = yup.object({
    email: yup
      .string()
      .email("Email is not valid")
      .required("Email is Required"),
  });
  const formik = useFormik({
    validationSchema: formikSchema,
    initialValues: {
      email: "",
    },
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
  });

  // Functions //

  const handleSubmit = async (e) => {
    let Data = {
      email: e.email,
    };
    try {
      if (!Data?.email) {
        return;
      }
      setloading(true);
      const Res = await addNewsletter(Data);

      toast.success(Res.data.message);
      setEmail("");
      setloading(false);
    } catch (error) {
      setloading(false);

      // toast.error(error.response.data.message);
    }
  };

  return (
    <div className={Styles.bgfooter}>
      <ToastContainer position="bottom-right" />

      <div className="container px-4 mx-auto ">
        <div className="grid grid-cols-12 ">
          <div className={Styles.first}>
            <li href="/" className="flex">
              <img
                src={Logo}
                alt="logo"
                className="object-contain cursor-pointer"
              />
              <p className={Styles.logoText}>Coin Official</p>
            </li>
            <p className={Styles.firsttext}>
              Coin Official is a new state-of-the-art
              <br /> coin voting website where users use
              <br /> tokens to vote on their favorite projects
              <br />
              <br />
              <img
                src={isActive ? DarkImage : LightImage}
                alt=""
                className="w-40"
              />
            </p>
            <div className="flex flex-col md:flex-row mt-0 justify-start gap-x-6">
              <p className={Styles.supportpages}>Support</p>
              <p className={Styles.supportpages}>Terms & Services</p>
              <p className={Styles.supportpages}>Terms & Conditions</p>
            </div>
          </div>
          <div className={Styles.second}>
            <div className="-mt-36 md:-mt-0 md:ml-0 ml-36 md:border-none border-l border-[#707070] px-3">
              <a
                href="https://contractwolf.io/projects/coin-official"
                target="_blank"
                className="text-black hover:text-black"
                rel="noreferrer"
              >
                <p className={Styles.link}>Audit</p>
              </a>
              <a
                href="https://whitepaper.coinofficial.io/"
                target="_blank"
                className="text-black hover:text-black"
                rel="noreferrer"
              >
                <p className={Styles.linkMargin}>Whitepaper</p>
              </a>
              <a
                href="https://bscscan.com/address/0xB3E8e9d378175f25502e47666073DCf10902b07A"
                target="_blank"
                className="text-black hover:text-black"
                rel="noreferrer"
              >
                <p className={Styles.linkMargin}>BSC Scan</p>
              </a>
              <a
                href="https://pitch.com/public/1130197d-6706-4359-b27a-1e0b6e4b5fe7"
                target="_blank"
                className="text-black hover:text-black"
                rel="noreferrer"
              >
                <p className={Styles.linkMargin}>Pitch Deck</p>
              </a>
            </div>
            <div className="flex ml-0 lg:ml-3 mt-12 gap-x-4 sm:gap-x-4">
              {/* <a href={facebook} target="_blank" rel="noreferrer">
                <img alt="facebook" src={Facebook} />
              </a> */}
              {/* <a href={linkedin} target="_blank">
                <img alt="Linkedin" src={Linkedin} />
              </a> */}
              <a href={twitter} target="_blank" rel="noreferrer">
                <img alt="Twitter" src={Twitter} />
              </a>
              <a href={discord} target="_blank" rel="noreferrer">
                <img alt="discord" src={Discord} />
              </a>
              <a href={telegram} target="_blank" rel="noreferrer">
                <img alt="Instgram" src={Telegram} />
              </a>
              <a href={github} target="_blank" rel="noreferrer">
                <img alt="Instgram" src={Github} />
              </a>
            </div>
          </div>
          <div className={Styles.third}>
            <p className={Styles.newsLetter}>Newsletter</p>
            <div className="flex justify-between">
              <p className="text-sm md:text-md font-medium dark:text-white">
                Subscribe Coin Official
              </p>
              <p className="dark:text-white text-[11px] md:text-[13px] mt-1">
                For Our Future Updates
              </p>
            </div>
            <div className="mt-3">
              <form onSubmit={formik.handleSubmit}>
                <div className="relative">
                  <button className={Styles.newsLetterImg} type="submit">
                    {loading ? (
                      <CommonButton
                        loading={loading}
                        width="25px"
                        height="25px"
                        disabled={loading ? true : false}
                      />
                    ) : (
                      <>
                        <img
                          alt="footer img"
                          src={imgSrc}
                          className={Styles.img1}
                        />
                        <img
                          alt="footer img"
                          src={LongArrow}
                          // onClick={handleSubmit}
                          className={Styles.img2}
                        />
                      </>
                    )}
                  </button>
                  <input
                    type="email"
                    name="email"
                    {...formik.getFieldProps("email")}
                    id="input-group-1"
                    className={Styles.footerInput}
                    placeholder={
                      formik.errors.email ? formik.errors.email : "Your Email"
                    }
                  />
                </div>
                {formik.errors.email && formik.touched.email ? (
                  <div style={{ color: "#E5516B" }}>{formik.errors.email}</div>
                ) : null}
              </form>
            </div>
            <div className="dark:text-white text-[13px] mt-[90px]">
              <div className="flex">
                <AiOutlineCopyrightCircle className="mt-1" />
                <p className="ml-1">
                  {" "}
                  {new Date().getFullYear()} Coin Official. All rights reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
