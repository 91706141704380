import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: [],
  editCoin: null,
  userCoin: [],
  usePools: [],
  editStatus: false
};
export const AddCoin = createSlice({
  name: "Coin",
  initialState,
  reducers: {
    saveAddCoin(state, action) {
      state.data = action.payload;
    },
    saveUserCoin(state, action) {
      state.userCoin = action.payload;
    },
    saveUserPools(state, action) {
      state.usePools = action.payload;
    },
    saveEditCoin(state, action) {
      state.editCoin = action.payload
    },
  },
});
export const { saveAddCoin, saveUserCoin, saveUserPools, saveEditCoin } = AddCoin.actions;
export default AddCoin.reducer;
