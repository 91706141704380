import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  Orders: [],
  favCoin: [],
  MarketplaceAccount: [],
  FavouriteCoin: []

};
export const Portfolio = createSlice({
  name: "Portfolio",
  initialState,
  reducers: {
    saveOrders(state, action) {
      state.Orders = action.payload;
    },
    saveFavCoin(state, action) {
      state.favCoin = action.payload;
    },
    saveMarkeplaceAccount(state, action) {
      state.MarketplaceAccount = action.payload;
    },
    saveFavouriteAccount(state, action) {
      state.FavouriteCoin = action.payload;
    },
  },
});
export const { saveOrders, saveFavCoin, saveMarketplaceAccount, saveFavouriteAccount } = Portfolio.actions;
export default Portfolio.reducer;
