import React from "react";
import NoDataFound from "../__common/noDataGif/noDataFound";
import ReactHelemet from "../__common/ReactHelmet";
import styles from "./pageNotFound.module.scss";

const PageNotFound = () => {
  return (
    <>
      <ReactHelemet />

      <div className={styles.containerr}>
        <div className={styles.wrapper}>
          <NoDataFound />
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
