import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  isActive: true,
};
export const Layout = createSlice({
  name: "Layout",
  initialState,
  reducers: {
    saveTheme(state, action) {
      state.isActive = action.payload;
    },
  },
});
export const { saveTheme } = Layout.actions;
export default Layout.reducer;
