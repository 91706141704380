import styles from "./layout.module.scss";
import Navigation from "../Navbar";
import Footer from "../Footer";
import PreNav from "../PreNav";
import { saveTheme } from "../../../store/Reducers/layout";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const Layout = ({ children }) => {
  const dispatch = useDispatch();

  // This Function(dispatch(saveTheme(!isActive));) is used for theme changing for the website from a light theme to a dark theme.

  // const [isActive, setIsActive] = useState(true);
  const isActive = useSelector((state) => state.Layout.isActive);
  const handleClick = () => {
    dispatch(saveTheme(!isActive));
    // setIsActive((current) => !current);
  };

  return (
    <div className={isActive ? "dark" : ""}>
      <div className={styles.light}>
        <div>
          <PreNav handleClick={handleClick} isActive={isActive} />
          <Navigation isActive={isActive} />
          <div className=" ">{children}</div>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
