import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers, compose } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ReduxThunk from "redux-thunk";
import metamasktoken from "./Reducers/metamasktoken";
import AddCoin from "./Reducers/AddCoin";
import CoinDetails from "./Reducers/coinDetail";
import homereducer from "./Reducers/homereducer";
import OwnerPool from "./Reducers/Stacking";
import Marketplace from "./Reducers/marketplace";
import Contact from "./Reducers/contact";
import Layout from "./Reducers/layout";
import Portfolio from "./Reducers/portfolio";
// import JSOG from 'jsog';

import unstakeContract from "./Reducers/unstake";
const persistConfig = {
  key: "root",
  storage: storage,
  //transforms: [JSOGTransform],
  whitelist: [
    "token",
    "Coin",
    "CoinDetail",
    "Home",
    "Layout",
    "Portfolio",
    "marketplace",
    "Contact",
  ],
  blacklist: [],
};
const reducers = combineReducers({
  token: metamasktoken,
  Coin: AddCoin,
  Layout: Layout,
  Contact: Contact,
  CoinDetail: CoinDetails,
  Home: homereducer,
  Portfolio: Portfolio,
  ownerpool: OwnerPool,
  marketplace: Marketplace,
  unstakeContract: unstakeContract,
});
const persistedReducer = persistReducer(persistConfig, reducers);
const middleware = getDefaultMiddleware({
  serializableCheck: false,
  immutableCheck: false,
}).concat(ReduxThunk);
let enhancedCompose = compose;
// if (__DEV__) {
//   enhancedCompose = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;
// }
export const store = configureStore({
  reducer: persistedReducer,
  middleware: enhancedCompose(middleware),
});
export const persistor = persistStore(store);
// export type RootState = ReturnType<typeof reducers>;
// export type AppDispatch = typeof store.dispatch;
