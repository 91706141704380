import React from 'react'
import { Helmet } from 'react-helmet'

const ReactHelemet = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Coin Official</title>
      <meta
        name="description"
        content="Coin Official is a state-of-the-art cryptocurrency voting website that allows users to use tokens to vote on their favorite projects."
      />
    </Helmet>
  )
}

export default ReactHelemet