import { HTTP_CLIENT } from "../utils/request";

export const contactLinks = async (param) => {
  return await HTTP_CLIENT.get("/settings", param);
};

export const getAllData = async () => {
  return await HTTP_CLIENT.get("/settings/getFrontend");
};


export const getWallets = async () => {
  return await HTTP_CLIENT.get("/external-wallet")
}