import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  ownerPool: [],
  contractsPool: [],
  coinPools:[]
};
export const Stacking = createSlice({
  name: "Coin",
  initialState,
  reducers: {
    resetOwnerPool: (state) => initialState,
    saveOwnerPool(state, action) {
      state.ownerPool = action.payload;
    },
    saveContractsPool(state, action) {
      state.contractsPool = action.payload;
    },
    saveCoinPools(state, action) {
      state.coinPools = action.payload;
    }

  },
});
export const { saveOwnerPool, resetOwnerPool,saveContractsPool,saveCoinPools } = Stacking.actions;
export default Stacking.reducer;
