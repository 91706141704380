import WalletConnectProvider from "@walletconnect/web3-provider";
import STACKING_ABI from "../ABI/Stacking_New_ABI.json";
import { ABC } from "../ABI/Staking_Byte";
// import ByteCode from "../ABI/Staking_Byte.js"

// Address for add coin fee and other
export const ProjectWalletAddress = "0xbe79573345880d30fb6ed4143d6e05cc79771039"

// Address for Create Fee
export const CreatePoolFeeAddress = "0x0c906197765FC4Ba7d06dB002131A5336Cb65caB"


// ----- Cridentials for Live starts here-----//

export const BASE_URL = "https://api.coinofficial.io/";
export const ChainID = 56;
export const RPC_URL = "https://bsc-dataseed.binance.org/"
export const blockExplorerUrls = "https://bscscan.com/"
export const STACKING_CONTRACT = "0x601bD02e2d75290D85c99e9A182928B466192A88";
export const COINOFFICIAL_ADDRESS = "0x3c7fe809D0550E505e8A85D63Ce76292FcDFb634";
export const ROUTER_CONTRACT = "0x10ED43C718714eb63d5aA57B78B54704E256024E"
export const VOTE_CONTRACT_OLD = "0x673F585E11f4A56124ae964C3Bd839748b1aad23";
export const VOTE_CONTRACT = "0xB3E8e9d378175f25502e47666073DCf10902b07A";
export const WRAPPED_BNB_CONTRACT = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";


// export const ZAH_CONTRACT = "0xE061156135B7b7847FD4dB74992ac8555C0CD5A7"

export const DEPLOYMENT_CONTRACT = {
  rpc: `${RPC_URL}`,
  abi: STACKING_ABI,
  bytecode: ABC
}

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: `${RPC_URL}`,
      },
      chainId: ChainID,
    },
  },
};


export const BNB_NETWORK = {
  chainId: "0x38",
};

// ----- Cridentials for Live ends here-----//


// export const STACKING_CONTRACT_OLD = "0x601bD02e2d75290D85c99e9A182928B466192A88";
// export const STACKING_CONTRACT_OLD = "0xd8386C2adBd283E5337779aB7c9C4ba83E575c81";


// ----- Cridentials for Stagging starts here-----//
// export const BASE_URL = "https://api.coinofficial.io/";
// export const BASE_URL = "https://api.coinofficial.io/";
// export const BASE_URL = "http://192.168.50.110:5000/";
// export const BASE_URL = "https://stg-api.coinofficial.io/";
// export const ChainID = 97;
// export const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/"
// export const blockExplorerUrls = "https://testnet.bscscan.com"
// export const ROUTER_CONTRACT = "0xD99D1c33F9fC3444f8101754aBC46c52416550D1"
// export const VOTE_CONTRACT = "0x98b7c1f8B041994aFFD919006F1009e660f5449a";
// export const STACKING_CONTRACT = "0xD73610f5d84707c84125FC74C389cC2e0720c420";
// export const WRAPPED_BNB_CONTRACT = "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
// export const COINOFFICIAL_ADDRESS = "0x3c7fe809D0550E505e8A85D63Ce76292FcDFb634";

// export const DEPLOYMENT_CONTRACT = {
//   rpc: `${RPC_URL}`,
//   abi: STACKING_ABI,
//   bytecode: ABC
// }

// export const providerOptions = {
//   walletconnect: {
//     package: WalletConnectProvider,
//     options: {
//       rpc: {
//         97: `${RPC_URL}`,
//       },
//       chainId: `${ChainID}`,
//       network: "binance-testnet",
//     },
//   },
// };
// export const BNB_NETWORK = {
//   chainId: "0x61",
// };

// ----- Cridentials for Stagging ends here-----///
