import React from "react";
import Main from "../../Components/Assests/maintain.svg";
import CoinLogo from "../../Components/Assests/Group 2.png"
import styles from "./maintain.module.scss";

const Maintainence = () => {
  return (
    <div className={styles.bg}>
      <div className={styles.title}>SITE UNDER MAINTENANCE</div>
      <div className={styles.description}>
        <img src={CoinLogo} alt="maintainence-logo" className="w-14" />
        Coin Official</div>
      <div className="flex justify-center items-center h-screen">
        <img
          className="w-1/2 lg:w-1/3 xl:w-1/3 mx-auto mt-11 lg:mt-28"
          src={Main}
          alt="maintainenece"
        />
      </div>
    </div>
  );
};

export default Maintainence;
