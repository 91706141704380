import React from "react";
import styles from "./header.module.scss";
import HeaderData from "./headerData";

export const Header = () => {
  return (
    <div className={styles.gridd}>
      {HeaderData?.map((item) => {
        return (
          <div className={styles.spann} key={item.id}>
            <div className={styles.bgCard}>
              <p className={styles.heading}>{item.heading}</p>
              <p className={styles.amount}>{item.amount}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};
