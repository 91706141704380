const HeaderData = [
    {
        id: 1,
        amount : "$399,916,277,418",
        heading : 'Balance',

    },
    {
        id: 2,
        amount : "$39,9916",
        heading : 'Total Claim',
        
    },
    {
        id: 3,
        amount : "$39,9916",
        heading : 'Pending Claim',
        
    },

]
export default HeaderData