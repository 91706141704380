/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Modal from "../../Modal";
import styles from "./stakeAmount.module.scss";





const CartInfo = ({
  setPopupVisible,
  popupVisible,
  togglePopup,
  cartInfo
}) => {
  return (
    <div className="w-1/2">
      <Modal
        visible={popupVisible}
        onClose={() => {
          setPopupVisible(false);
        }}
        togglepopup={togglePopup}
      >
        <div className="grid grid-cols-12">
          <div className="col-span-12">
            <div className={styles.cardBg}>
              <div className="flex flex-col items-center justify-center text-center">
                <p className={styles.text}>
                  MarketPlace Package Details</p>

                <p className="dark:text-white text-black text-md text-left">
                  {cartInfo?.cartDetails}</p>
                <div className="flex mt-3 w-full justify-center gap-1">
                  <button
                    className={styles.cancelButton}
                    onClick={() => setPopupVisible(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CartInfo;
