import NFT from '../../Assests/nft4.svg'
import NFT1 from '../../Assests/Mask Group 34.svg'
import NFT2 from '../../Assests/Mask Group 35.svg'
import Nft3 from '../../Assests/Mask Group 33.svg'

 
const NftData = [
    {
        id: 1,
        Pic : NFT,
        name : 'Oqtopus',

    },
    {
        id: 2,
        Pic : NFT1,
        name : 'Oqtopus',
        
    },
    {
        id: 3,
        Pic : NFT2,
        name : 'Oqtopus',
        
    },
    {
        id: 4,
        Pic : Nft3,
        name : 'Oqtopus',
        
    },
    {
        id: 3,
        Pic : NFT2,
        name : 'Oqtopus',
        
    },
    {
        id: 3,
        Pic : NFT1,
        name : 'Oqtopus',
        
    },
]
export default NftData