import styles from "./noData.module.scss";
import NoDataFoundNew from "../../Assests/newDataFoundNew.gif";
import NoDataMetaMask from "../../Assests/connect wallet.png";
import UseModalInjector from "../../../utils/helper/useWeb3Modal";
import { useCallback } from "react";
import {
  getAccountDetails,
  walletConnectModal,
} from "../../../utils/helper/web3ModalConnector";
import { setMobileUIWallet } from "../../../store/Reducers/metamasktoken";
import { useDispatch, useSelector } from "react-redux";
const NoDataFound = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <img src={NoDataFoundNew} alt="" />
          <label className="dark:text-white text-black">No Data Found</label>
        </div>
      </div>
    </>
  );
};

export default NoDataFound;

export const NoDataFoundMetaMask = () => {
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  const dispatch = useDispatch();
  const { handleConnectModal } = UseModalInjector();

  // openMobileUIWallet function is designed to handle the logic for opening a mobile UI wallet. If metaMaskToken is present (indicating that MetaMask is available), it will open the wallet connect modal and then fetch account details. Otherwise, it will trigger an action to set the mobile UI wallet state to true, presumably for handling the scenario when MetaMask is not available.

  const openMobileUIWallet = useCallback(async () => {
    if (metaMaskToken) {
      await walletConnectModal();
      await getAccountDetails();
    } else {
      await dispatch(setMobileUIWallet(true));
    }
  }, [dispatch, getAccountDetails, metaMaskToken, walletConnectModal]);

  return (
    <>
      <div className={styles.Walletwrapper}>
        <div className={styles.ConnectWallet}>
          <img src={NoDataMetaMask} alt="" />
          <label className="text-black dark:text-white  text-xl font-bold">
            Please Connect Your Wallet.
          </label>
          <button onClick={openMobileUIWallet} className="flex lg:hidden">
            Connect Wallet
          </button>
          <button onClick={handleConnectModal} className="hidden lg:flex">
            Connect Wallet
          </button>
        </div>
      </div>
    </>
  );
};
