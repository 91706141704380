import React from 'react'
import "./styles.css"
import styles from "./stylesDark.module.scss"
import { useSelector } from 'react-redux';
function Scrollbar({ children }) {
  const isActive = useSelector((state) => state.Layout.isActive);
  return (
    <>
      <div className={styles.ScrollbarWrapper}>
        {children}
      </div>
    </>
  )
}

export default Scrollbar;
