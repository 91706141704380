/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./card.module.scss";
import { CommonButton } from "../../__common/loader/Loader";
import Delete from "../../Assests/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearCart, deleteCart } from "../../../store/Reducers/homereducer";
import { toast, ToastContainer } from "react-toastify";
import { payBNB } from "../../../utils/helper/connector";
import { addToCard, validateDiscountCode } from "../../../services/Home";
import {
  BNB_NETWORK,
  ProjectWalletAddress,
} from "../../../utils/constans/appconstans";
import { useNavigate } from "react-router";
import BNBLogo from "../../Assests/bnb.svg";
import DropDown from "../Dropdown";
import EmptyCart from "../../Assests/cartEmpty.svg";
import CartInfo from "../../__common/Popups/CartInfo";
export const CartCard = () => {
  const { cart } = useSelector((state) => state.Home);
  // const { profitWallet } = useSelector((state) => state.Contact);
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);
  // const trx = useSelector((state) => state?.Contact?.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cartInfo, setCartInfo] = useState(null);
  const [total, setTotal] = useState(null);
  const [email, setEmail] = useState("");
  const [discountCode, setDiscountcode] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(null);
  const [reload, setReload] = useState(false);
  let [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [selected, setSelected] = useState("");
  const [openCartModal, setOpenCartModal] = useState(false);
  const toggleCartInfoPopup = (e) => {
    setCartInfo(e);
    setOpenCartModal((preview) => !preview);
  };

  const handleDiscountCode = (e) => {
    setDiscountcode(e.target.value);
  };

  // In short, handleEmail is a handler function used to validate and handle changes in an email input field. It checks if the user input matches a simple email pattern, updates the email state with the input value, and sets the emailError state to indicate whether the email is valid or not.

  const handleEmail = (e) => {
    setEmail(e.target.value);
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    const { value } = e.target;
    if (value.match(pattern)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email");
    }
  };

  const handleFilter = (item) => {
    dispatch(deleteCart(item));
  };

  // calculateDiscount calculates the discounted value given a percentage discount and an original value. If both values are valid (not falsy), it calculates the discount and returns the discounted value; otherwise, it returns the original value without any discount.

  const calculateDiscount = (percent, value) => {
    let totalValue;
    if (value && percent) {
      totalValue = (1 - Number(percent) / 100) * value;
      return totalValue;
    } else {
      totalValue = value;
      return totalValue;
    }
  };

  useEffect(() => {
    if (cart) {
      const sum = cart.reduce(
        (partialSum, a) => partialSum + Number(a.price),
        0
      );
      if (discountCode === 0) {
        const response = calculateDiscount(sum);
        // let ConverTotalAmount = ((sum) + (Number(trx.trxFee)))
        setTotal(response);
      } else {
        const response = calculateDiscount(discountPercentage, sum);
        // let ConverTotalAmount = ((sum) + (Number(trx.trxFee)))
        setTotal(response);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, reload]);

  // meta mask network change function

  const changeNetwork = async () => {
    try {
      if (!window.ethereum) throw new Error("No crypto wallet found");
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [BNB_NETWORK],
      });
    } catch (err) {
      if (err.message === "User rejected the request.") {
        toast.error(err.message);
        navigate("/");
      }
    }
  };

  // In short, handlegGetPercentage is a handler function that validates a discount code, updates the discount percentage and validation status in the state, and shows toast messages to notify the user about the validation result. It also resets relevant state variables after the validation process.

  const handlegGetPercentage = async () => {
    try {
      setValidateLoading(true);
      const response = await validateDiscountCode(discountCode);
      if (response.data) {
        setDiscountPercentage(response?.data?.coupon?.discountPercentage);
        setIsValidate(true);
        toast.success("Code Validated Successfully");
      } else {
        toast.error("Invalid Code");
        setDiscountPercentage(0);
        setIsValidate(false);
      }
      setReload(!reload);
      setValidateLoading(false);
      setDiscountcode("");
    } catch (error) {
      setIsValidate(false);
      toast.error("Invalid Code");
      setDiscountcode("");
      setReload(!reload);
      setDiscountPercentage(0);
      setValidateLoading(false);
    }
  };

  //  handleSubmitCard is a handler function that handles the order submission process with Metamask integration in a React application. It performs various validations related to Metamask connection, cart items, email, and discount code. If all validations pass, it attempts to pay for the order, saves the order details, clears the cart, and shows success or error toast messages accordingly.

  const handleSubmitCard = async () => {
    if (!metaMaskToken) {
      toast.error("Please connect metamask first");
      return;
    }
    if (cart?.length === 0) {
      toast.error("Cart cannot be empty");
      return;
    }
    if (window.ethereum.chainId === "0xa869") {
      changeNetwork();
      return;
    }
    if (discountCode !== "") {
      toast.error("Please validate code first");
      return;
    }
    if (!email) {
      toast.error("Please enter email first");
      return;
    }

    if (emailError === "Invalid email") {
      return;
    }

    try {
      setLoading(true);
      const response = await payBNB(metaMaskToken, total, ProjectWalletAddress);
      if (response?.code === 4001) {
        setLoading(false);
        return;
      }
      let param = {
        payload: cart,
        paymentTransaction: response,
        email: email,
        couponDiscount: discountPercentage ? discountPercentage : 0,
      };
      if (response?.status) {
        await addToCard(param);
        dispatch(clearCart());
        setEmail("");
        setLoading(false);
        toast.success("Order placed successfully");
        setDiscountcode("");
      } else {
        setLoading(false);
      }
    } catch (error) {
      let err = error ? error.response.data.message : "something went wrong";
      setLoading(false);
      toast.error(err);
    }
  };

  useEffect(() => {
    setSelected(SelectionOptions[0]);
  }, []);
  const SelectionOptions = [
    {
      currency: "BNB",
      icon: BNBLogo,
    },
  ];

  return (
    <div>
      <CartInfo
        cartInfo={cartInfo}
        popupvisible={toggleCartInfoPopup}
        setPopupVisible={setOpenCartModal}
        popupVisible={openCartModal}
      />
      {cart?.length === 0 ? (
        <div className="w-full min-h-[50vh] lg:min-h-[70vh] flex flex-col  justify-center items-center">
          <img src={EmptyCart} alt="" className="w-24 lg:w-72 object-fill" />
          <p className="text-black dark:text-white text-lg mt-2">
            Cart is empty.
          </p>
        </div>
      ) : (
        <div className={styles.secondSpan}>
          <ToastContainer position="bottom-right" />
          <p className={styles.orderHeading}>Your Cart</p>
          <div className={styles.secondBg}>
            <div className={styles.uperbg}>
              <div className="flex justify-between">
                <p className="w-2/5">Name</p>
                <p className="w-1/3">Details</p>
                <p className="w-1/3 text-center">Price</p>
              </div>
            </div>

            {cart?.map((item, index) => (
              <div
                key={index}
                className="flex w-full  p-3 px-6 justify-between border-b-[1px] border-[#9ca3af] dark:border-[#5B5B5B]  text-white "
              >
                <p className="w-2/5 relative dark:text-white text-black">
                  {item?.coinSymbol
                    ? item?.coinSymbol + " " + "(Promote)"
                    : item?.title
                    ? item?.title + " " + "(Marketplace)"
                    : item?.type?.toUpperCase() + " " + "(Promote)"}
                </p>
                <p className="w-1/3 ml-0 dark:text-white text-black">
                  {item?.cartDetails ? (
                    <span
                      className="cursor-pointer text-sm  ml-1 text-blue-500"
                      onClick={() => toggleCartInfoPopup(item)}
                    >
                      Show
                    </span>
                  ) : (
                    "N/A"
                  )}
                </p>
                <p className="w-1/3 ml-0 flex items-center justify-center gap-x-1 text-center  dark:text-white text-black">
                  {Number(item?.price).toFixed(3)}{" "}
                  <img src={BNBLogo} className="w-4 h-4" alt="" />
                </p>
                <img
                  src={Delete}
                  alt="delete"
                  className="w-4 cursor-pointer"
                  onClick={() => handleFilter(item)}
                />
              </div>
            ))}
            <div className={styles.subTotal}>
              <div className="w-full hidden lg:flex justify-between items-center">
                <p className="dark:text-[#BFBFBF] text-black w-1/4">
                  Add Coupon Code If Applicable
                </p>
                <p className="dark:text-[#BFBFBF] text-black w-3/4text-center">
                  {discountPercentage ? `${discountPercentage}%` : "0%"}
                </p>
                <input
                  id="inline-full-name"
                  type="email"
                  required
                  placeholder="Enter Discount Code"
                  value={discountCode}
                  className={styles.DiscountInput}
                  onChange={(e) => handleDiscountCode(e)}
                />
                <div className={styles.validateButton}>
                  <CommonButton
                    click={handlegGetPercentage}
                    loading={validateLoading}
                    title="Validate Code"
                    height="25px"
                    width="25px"
                    isValidate={isValidate}
                    discountCode={discountCode}
                  />
                </div>
              </div>
              <div className="w-full flex lg:hidden justify-between items-center">
                <div className="w-[50%] flex flex-col justify-center items-center">
                  <p className="dark:text-[#BFBFBF] text-black w-full text-[10px] text-left">
                    Add Coupon Code If Applicable
                  </p>
                  <div className=" flex items-center justify-center dark:bg-black rounded-md">
                    <input
                      id="inline-full-name"
                      type="email"
                      required
                      placeholder="Enter Code"
                      value={discountCode}
                      className={styles.DiscountInput}
                      onChange={(e) => handleDiscountCode(e)}
                    />
                    <p className="dark:text-[#BFBFBF] text-black w-1/4  text-center dark:bg-black">
                      {discountPercentage ? `${discountPercentage}%` : "0%"}
                    </p>
                  </div>
                </div>
                <div className={styles.validateButton}>
                  <CommonButton
                    click={handlegGetPercentage}
                    loading={validateLoading}
                    title="Validate Code"
                    height="25px"
                    width="25px"
                    isValidate={isValidate}
                    discountCode={discountCode}
                  />
                </div>
              </div>
            </div>
            {cart?.map((item, index) => {
              return (
                <>
                  {item?.discount && (
                    <div className={styles.subTotal} key={index}>
                      <p className="dark:text-[#BFBFBF] text-black w-2/3">
                        {item?.discount ? item?.discountDays : ""} Days Promote
                        Discount{" "}
                      </p>
                      <p className="w-1/3">{item?.discount}%</p>
                      <p></p>
                    </div>
                  )}
                </>
              );
            })}
            <div className={styles.subTotal}>
              <p className="dark:text-[#BFBFBF] text-black w-2/3">Total</p>
              <p className="w-1/3 flex items-center gap-x-1">
                {Number(total)?.toFixed(3)}{" "}
                <img src={BNBLogo} className="w-4 h-4" alt="" />
              </p>
              <p></p>
            </div>
            <div className="px-6 mt-4 dark:bg-[#1C2024]  bg-[#eeecf0]">
              <div className="w-full gap-x-2 items-center mb-3 dark:text-white text-black flex flex-wrap">
                <input
                  id="inline-full-name"
                  type="email"
                  required
                  placeholder="Email Address"
                  value={email}
                  className={styles.InputStyling}
                  onChange={(e) => handleEmail(e)}
                />
                <DropDown
                  selected={selected}
                  isActive={false}
                  setSelected={setSelected}
                  options={SelectionOptions && SelectionOptions}
                  showRightIcon={false}
                />
                <div className={styles.button}>
                  <CommonButton
                    Cartt="cart"
                    click={handleSubmitCard}
                    loading={loading}
                    title="Pay"
                    height="25px"
                    width="25px"
                  />
                </div>
              </div>
              <p className="text text-red-600 mt-2 mx-2"> {emailError}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
