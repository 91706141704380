import React, { useEffect, useState } from "react";
import styles from "./claimHeader.module.scss";
import Telegram from "../../Assests/Telegream.svg";
import Twitter from "../../Assests/twtr.svg";
import Redit from "../../Assests/Group 11740.svg";
import Share from "../../Assests/share.svg";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CommonButton } from "../../__common/loader/Loader";
import { claims } from "../../../services/claim";
import { toast, ToastContainer } from "react-toastify";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { FaRegCopy } from "react-icons/fa";
import {
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import { HTTP_CLIENT } from "../../../utils/request";

const ClaimHeader = () => {
  const [availableBalance, setAvailableBalance] = useState({
    balance: 0,
    totalclaim: 0,
  });
  const { refferalCode } = useSelector((state) => state.Home);
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);

  // The claimGet function allows users to claim tokens for their wallet address (metaMaskToken) if they have a balance of at least 1000 $VOTE tokens. If eligible, it calls the claims API endpoint and displays success or error messages using toast.

  const cliamGet = async (e) => {
    if (availableBalance?.balance < 1000) {
      toast.error('Min. "1000 $VOTE" amount of tokens needed.');
      return;
    }
    // e.preventDefault();
    let Data = {
      walletAddress: metaMaskToken,
    };
    try {
      setLoading(true);
      const Res = await claims(Data);
      toast.success(Res.data);
      // toast.success(Res.data.result);
      // setEmail("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  // The getRefferl function fetches referral data for a user identified by their metaMaskToken and updates the availableBalance state with the user's balance and total claimed amount.

  const getRefferl = async () => {
    const response = await HTTP_CLIENT.get(
      `user/get-my-referrals/${metaMaskToken}`
    );
    setAvailableBalance({
      balance: response.data.user.balance,
      totalclaim: response.data.user.totalClaimed,
    });
  };

  useEffect(() => {
    if (metaMaskToken) {
      getRefferl();
    }
  }, [metaMaskToken]);

  const TwitterText = `Check out Coin Official with the first-ever Decentralized Coin Voting website! Built for project owners, influencers, and investors. They showcase real projects & the data behind them, unlike other sites. Use my referral code jISZCKoP All Human🙋‍♂️, NO BOTS! 🤖`;
  const TelegramText = `Check out Coin Official with the first-ever Decentralized Coin Voting website! Built for project owners, influencers, and investors. They showcase real projects & the data behind them, unlike other sites. Use my referral code jISZCKoP All Human🙋‍♂️, NO BOTS! 🤖`;
  const redditText = `Check out Coin Official with the first-ever Decentralized Coin Voting website! Built for project owners, influencers, and investors. They showcase real projects & the data behind them, unlike other sites. Use my referral code jISZCKoP All Human🙋‍♂️, NO BOTS! 🤖`;

  return (
    <>
      <ToastContainer position="bottom-right" />
      <div className={styles.gridd}>
        <div className={styles.spann}>
          <div className={styles.bgCard}>
            <p className={styles.heading}>Claimable Balance</p>
            <div className={styles.amountAndClaim}>
              <p className={styles.amount}>
                {availableBalance?.balance
                  ? Number(availableBalance?.balance).toFixed(4)
                  : 0}{" "}
                $VOTE Tokens
              </p>
              <div className={styles.buttonsave}>
                <CommonButton
                  title="Claim"
                  click={cliamGet}
                  loading={loading}
                  type="submit"
                  height="15px"
                  width="15px"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.spannTotal}>
          <div className={styles.bgCard}>
            <p className={styles.heading}>Total Claimed</p>
            <p className={styles.amount}>
              {availableBalance?.totalclaim
                ? Number(availableBalance?.totalclaim).toFixed(4)
                : 0}{" "}
              $Vote Tokens
            </p>
          </div>
        </div>
        <div className={styles.spannReferral}>
          <p className={styles.refferal}>Referral Code </p>
          <div className={styles.field}>
            <div className={styles.box}>
              <div className="flex flex-row justify-start">
                <input
                  type="text"
                  value={refferalCode}
                  className={styles.input}
                  readonly
                />

                <span className={styles.span}>
                  <CopyToClipboard
                    text={refferalCode}
                    onCopy={() => setCopied(true)}
                  >
                    <div className={styles.spanButtn}>
                      {copied ? (
                        <AiOutlineCheckCircle className="w-5 text-green-400" />
                      ) : (
                        <FaRegCopy className="text-xl text-[#D26BF5] cursor-pointer" />
                      )}
                    </div>
                  </CopyToClipboard>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-wrap justify-between mt-1">
            <div className="flex items-center">
              <p className="ml-0 mt-1 dark:text-white  text-sm">Share To</p>
            </div>
            <div className="flex gap-1">
              <TwitterShareButton url={TwitterText}>
                <div className={styles.imgbg}>
                  <img
                    src={Twitter}
                    alt="telegram"
                    className="cursor-pointer dark:invert-0 h-5 w-5"
                  />
                </div>
              </TwitterShareButton>
              <TelegramShareButton url={TelegramText}>
                <div className={styles.imgbg}>
                  <img
                    src={Telegram}
                    alt="twitter"
                    className="cursor-pointer dark:invert-0 h-5 w-5"
                  />
                </div>
              </TelegramShareButton>

              <RedditShareButton url={redditText}>
                <div className={styles.imgbg}>
                  <img
                    src={Redit}
                    alt="redit"
                    className="cursor-pointer dark:invert-0 h-5 w-5"
                  />
                </div>
              </RedditShareButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClaimHeader;
