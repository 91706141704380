import axios from "axios";
import { store } from "../store";
import { BASE_URL } from "./constans/appconstans";
export const HTTP_CLIENT = axios.create({
  baseURL: BASE_URL,
});
export const setupAxios = () => {
  HTTP_CLIENT.interceptors.request.use(
    (config) => {
      const authToken = store.getState()?.user?.accessToken;
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      return config;
    },
    (err) => {
      Promise.reject(err);
    }
  );
};
