import React, { useState } from "react";
import styles from "./DropDown.module.scss";
import ArrowDown from "../../Assests/icondown.svg";
import DollarIcon from "../../Assests/dollarIcon.svg";
import OutsideClickHandler from "react-outside-click-handler";

// import EthIcon from "../../../Assests/ethrm.svg"
// import BscIcon from "../../../Assests/bsc.png"
const DropDown = ({ selected, setSelected, options, showRightIcon, width }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className={styles.dropdown}>
      <div
        className={styles.dropdownbtn}
        onClick={(e) => setIsActive(!isActive)}
      >
        {showRightIcon === false ? null : (
          <div className={styles.imgContainer}>
            <img className={styles.leftIcon} src={DollarIcon} alt="Dollar" />
          </div>
        )}
        <span className={styles.selectedOptions}>{selected?.currency}
          <img src={selected?.icon ? selected?.icon : ""} alt="" />
        </span>
        <img className={styles.withRightIcon} src={ArrowDown} alt="Dropdown" />
      </div>

      {isActive && (
        <OutsideClickHandler
          onOutsideClick={() => {
            setIsActive(false);
          }}
        >
          <div className={styles.dropdowncontent}>
            {options?.map((option) => (
              <div
                onClick={(e) => {
                  setSelected(option);
                  setIsActive(false);
                }}
                className={styles.dropdownitems}
              >
                {option?.currency} <img src={option?.icon ? option?.icon : ""} alt="" />
              </div>
            ))}
          </div>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default DropDown;
