import React from "react";
import ClaimTable from "./ClaimTable";
import ClaimHeader from "./ClaimHeader";
import styles from "./referralsClaim.module.scss";
// import { HTTP_CLIENT } from "../../utils/request";
// import { useSelector } from "react-redux";
// import { CommonButton } from "../__common/loader/Loader";
// import Not from "../Assests/no-data-found.gif";
// import { Helmet } from "react-helmet-async";

export const ReferralClaim = () => {


  return (
    <>
      <div className={styles.bg}>
        <ClaimHeader />
        <ClaimTable />
      </div>
    </>
  );
};
