import React from "react";
import ReactHelemet from "../__common/ReactHelmet";
import { CartCard } from "./Card";

const Cart = () => {
  return (
    <div>
      <ReactHelemet />
      <CartCard />
    </div>
  );
};

export default Cart;
