import Web3 from "web3";
// import STACKING_ABI from "../ABI/stacking_ABI.json";
import STACKING_ABI from "../ABI/Stacking_New_ABI.json";
import ERC20_ABI from "../ABI/ERC20_ABI.json";
import ERC20_ABI_TRANSFER from "../ABI/ERC20_Trasnfer_ABI.json"
import ROUTER_ABI from "../ABI/router_ABI.json";
import VOTE_ABI from "../ABI/vote_ABI.json";
import ERC20_ETH_ABI from "../ABI/ERC20_ETH.json"
// import ZAH_ABI from "../ABI/zah_ABI.json"
import {
  STACKING_CONTRACT,
  DEPLOYMENT_CONTRACT,
  COINOFFICIAL_ADDRESS,
  VOTE_CONTRACT,
  ROUTER_CONTRACT,
} from "../constans/appconstans";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Provider } from "web3modal";
import { walletConnectModal } from "../helper/web3ModalConnector";
// const EthereumTx = require('ethereumjs-tx').Transaction;

export async function GetProvider() {
  // const { account } = useActiveWeb3React();
  // const web3 = new Web3();
  // web3.setProvider(window.web3.currentProvider);
  const web3 = await walletConnectModal();
  return web3;
}

export const GetStackingContract = async () => {
  try {
    let web3 = await GetProvider();
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(STACKING_ABI, STACKING_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) { }
};

export const GetCoinContract = async () => {
  try {
    let web3 = await GetProvider();
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(ERC20_ABI, COINOFFICIAL_ADDRESS);
      return MyContract;
    } else return null;
  } catch (error) { }
};

export const GetTransferTokenContract = async (coinAddress) => {
  try {
    let web3 = await GetProvider();
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(ERC20_ABI_TRANSFER, coinAddress);
      return MyContract;
    } else return null;
  } catch (error) { }
};

export const GetDeploymentContract = async (abi) => {
  try {
    const web3 = new Web3(DEPLOYMENT_CONTRACT.rpc);
    return new web3.eth.Contract(abi);
  } catch (err) { }
};

export const GetVoteContract = async () => {
  try {
    let web3 = await GetProvider();
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(ROUTER_ABI, ROUTER_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) { }
};

export const GetVoteTokenContract = async () => {
  try {
    let web3 = await GetProvider();
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(VOTE_ABI, VOTE_CONTRACT);
      return MyContract;
    } else return null;
  } catch (error) { }
};

export const GetCommonTokenContract = async (coinAddress) => {
  try {
    let web3 = await GetProvider();
    if (web3.currentProvider) {
      const MyContract = new web3.eth.Contract(ERC20_ETH_ABI, `"${coinAddress}"`);
      return MyContract;
    } else return null;
  } catch (error) { }
};

// export const getTestContract = () => {
//   const provider = new WalletConnectProvider({
//     infuraId: 'c944b72ce9b74c77aac906c6a59f4e99', // Required

//   });




//   let walletConnectWeb3 = new Web3(provider);

//   return new walletConnectWeb3.eth.Contract(VOTE_ABI, VOTE_CONTRACT);

// }
// --------------Zah Contract for Mainnet Testing-----//

// export const GetZahTokenContract = async () => {
//   try {
//     let web3 = GetProvider();
//     if (web3.currentProvider) {
//       const MyContract = new web3.eth.Contract(ZAH_ABI, ZAH_CONTRACT);
//       return MyContract;
//     } else return null;
//   } catch (error) { }
// };