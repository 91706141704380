import React, { useEffect } from "react";
import { Header } from "./Header";
import Card from "./Card";
import NoDataFound, { NoDataFoundMetaMask } from "../__common/noDataGif/noDataFound";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet-async";
import ReactHelemet from "../__common/ReactHelmet";

export const NftClaim = () => {
  const metaMaskToken = useSelector((state) => state.token.metaMaskToken);

  useEffect(() => {
    if (!metaMaskToken) {
      toast.error("Please connect to metmask first")
    }
  }, [metaMaskToken])

  return (
    <>
      <ReactHelemet />
      <div className="container px-6 lg:px-20 mx-auto">
        {metaMaskToken ?
          <>
            <Header />
            <Card />
          </>
          :
          <div className="w-full min-h-[20rem] flex justify-center">
            <NoDataFoundMetaMask />
          </div>
        }
      </div>
    </>
  );
};
