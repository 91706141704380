import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  data: [],
  profitWallet: null,
  marketRate: null,
  marketRateInUSD: null
};
export const Contact = createSlice({
  name: "Contact",
  initialState,
  reducers: {
    saveContact(state, action) {
      state.data = action.payload;
    },
    saveMarketRate(state, action) {
      state.marketRate = action.payload;
    },
    saveMarketRateInUSD(state, action) {
      state.marketRateInUSD = action.payload;
    },
    saveProfitWallet(state, action) {
      let filterProfitWallet = action?.payload?.filter((item) => (item?.type === "profitWallet"))
      state.profitWallet = filterProfitWallet[0];
    },

  },
});
export const { saveContact, saveMarketRate, saveMarketRateInUSD, saveProfitWallet } = Contact.actions;
export default Contact.reducer;
