import React from "react";
import styles from "./card.module.scss";
import NftData from "./NftCardData";
import NftMint from "../../__common/Popups/NftMintPopup";
import { useState } from "react";
const Card = () => {
  const [popupVisible, setPopupVisible] = useState(false);
  const togglePopup = (e, num) => {
    setPopupVisible((preview) => !preview);
  };
  return (
    <>
      <div className="text-white mt-5">
        <div className={styles.flexx}>
          <p className={styles.heading}>NFT Claim</p>
          <div>
            <button onClick={togglePopup}>Mint NFT</button>
          </div>
        </div>
      </div>
      <div className={styles.gridd}>
        {NftData?.map((item) => {
          return (
            <div className={styles.spann} key={item.id}>
              <div className={styles.bgCard}>
                <img src={item.Pic} className="w-full" alt="nft" />
                <p className={styles.name}>{item.name}</p>
                <div className="w-full px-3 pb-2">
                  <button className={styles.claimbtn}>Claim</button>
                </div>
              </div>
            </div>
          );
        })}
        <NftMint
          togglePopup={togglePopup}
          popupVisible={popupVisible}
          setPopupVisible={setPopupVisible}
        />
      </div>
    </>
  );
};

export default Card;
