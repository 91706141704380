import React from 'react'
import Modal from '../../Modal'
import Minus from "../../../Assests/Group 11745.svg";
import Plus from "../../../Assests/Group 11744.svg";

import { NavLink } from 'react-router-dom';
import styles from './nftMint.module.scss'
import { useState } from 'react';


const NftMint = ({setPopupVisible, popupVisible, togglePopup}) => {
    const [counter, setCounter] = useState(1)
  return (

  


    <div className="w-1/2">
      <Modal
        visible={popupVisible}
        btn
        onClose={() => setPopupVisible(false)}
        togglepopup={togglePopup}
      >
          <div className="grid grid-cols-12"> 
        
            <div className="col-span-12">
            <div className={styles.cardBg}>
            <div className="text-center">
              <p className={styles.heading}>Mint your NFT</p>
              <p className={styles.text}>Public Mint</p>
              <p className={styles.heading}>2399/10000 Minted</p>
              <p className={styles.text}>
                0.077 ETH | 12 max per wallet
              </p>
              <p className={styles.quantity}>Quantity</p>
              <div className={styles.counterBg}>
                <img src={Minus} className="cursor-pointer" alt="minus" onClick={() => { (counter == 0) ? setCounter(0) : setCounter(counter - 1) }}/>
                <p>
                  {counter}
                </p>
                <img src={Plus} alt="plus" className="cursor-pointer" onClick={()=>setCounter(counter + 1)}/>
              </div>
              <div className={styles.flexx}>
                <input type="checkbox" className="accent-[#C16BF2] w-4 border border-[#C16BF2]" />
                <p>
                  I agree to the Terms and Conditions
                </p>
              </div>
            

              <button onClick={()=>setPopupVisible(false)}>Mint Now</button>
             
            </div>
          </div>
         
            </div>
          </div>

      </Modal>
    </div>
  );
};



export default NftMint